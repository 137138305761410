import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

export const query = graphql`
  query PostsByID($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        author {
          name
        }
        social {
          twitterCreator
          fbAppID
        }
      }
    }
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        dateFormatted: date(formatString: "MMMM Do, YYYY")
        description
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
        coverShare: cover {
          childImageSharp {
            gatsbyImageData(width: 1200)
          }
        }
        coverAlt
      }
    }
  }
`

function coverImg(cover, coverAlt) {
  if (cover != null) {
    const image = getImage(cover)
    return <GatsbyImage image={image} alt={coverAlt} className="rounded mb-3" />
  } else {
    return
  }
}

export default function PostPage({ data, location }) {
  const { title, siteUrl, author, social } = data.site.siteMetadata
  const { frontmatter, body } = data.mdx

  const pageUrl = siteUrl + location.pathname
  const imageShareUrl =
    siteUrl +
    frontmatter.coverShare.childImageSharp.gatsbyImageData.images.fallback.src
  const imageShareWidth =
    frontmatter.coverShare.childImageSharp.gatsbyImageData.width
  const imageShareHeight =
    frontmatter.coverShare.childImageSharp.gatsbyImageData.height

  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        authorInHeadTitle={true}
        defaultTitle={title}
        description={frontmatter.description}
        siteUrl={siteUrl}
        pageUrl={pageUrl}
        author={author.name}
        imageUrl={imageShareUrl}
        imageWidth={imageShareWidth}
        imageHeight={imageShareHeight}
        imageAlt={frontmatter.coverAlt}
        fbAppID={social.fbAppID}
        twitterCreator={social.twitterCreator}
        datePublished={frontmatter.date}
        isBlogPost={true}
      />
      <div className="container">
        <div class="row">
          <article className="mb-5">
            <div className="col-lg-12 col-xl-11 col-xxl-10 mx-auto rounded">
              {coverImg(frontmatter.cover, frontmatter.coverAlt)}
            </div>
            <div className="col-lg-10 col-xl-9 col-xxl-8 mx-auto mt-3">
              <h1 className="fs-1 fw-bold mb-2">{frontmatter.title}</h1>
              <p className="text-muted fst-italic mb-2">
                {frontmatter.description}
              </p>
              <p className="small text-muted fst-italic mb-5">
                {frontmatter.dateFormatted}
              </p>
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  )
}
