import React from "react"
import { Helmet } from "react-helmet"

// SEO in Gatsby examples:
//   - https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SEO.js
//   - https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/master/src/components/SEO/SchemaOrg.js
//   - https://github.com/kentcdodds/kentcdodds.com/blob/main/src/components/seo/index.js

export default function Seo({
  title,
  authorInHeadTitle,
  defaultTitle,
  description,
  siteUrl,
  pageUrl,
  author,
  imageUrl,
  imageWidth,
  imageHeight,
  imageAlt,
  fbAppID,
  twitterCreator,
  datePublished = null,
  isBlogPost = false,
}) {
  const schemaOrg = createSchemaOrg(
    title,
    defaultTitle,
    description,
    siteUrl,
    pageUrl,
    author,
    imageUrl,
    imageWidth,
    imageHeight,
    imageAlt,
    datePublished,
    isBlogPost
  )

  return (
    <Helmet>
      {/* General tags */}
      {authorInHeadTitle ? (
        <title>{`${title} • ${description} • ${author}`}</title>
      ) : (
        <title>{`${title} • ${description}`}</title>
      )}
      <meta name="description" content={description} />
      <meta name="image" content={imageUrl} />
      <link rel="canonical" href={pageUrl} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={pageUrl} />
      <meta property="og:type" content={isBlogPost ? "article" : "website"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:image:alt" content={imageAlt} />
      {fbAppID ? <meta property="fb:app_id" content={fbAppID} /> : null}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterCreator ? (
        <meta name="twitter:creator" content={twitterCreator} />
      ) : null}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:image:alt" content={imageAlt} />

      {/* Schema Org - https://schema.org */}
      <script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
    </Helmet>
  )
}

function createSchemaOrg(
  title,
  defaultTitle,
  description,
  siteUrl,
  pageUrl,
  author,
  imageUrl,
  imageWidth,
  imageHeight,
  imageAlt,
  datePublished,
  isBlogPost
) {
  const imageObject = {
    "@context": "https://schema.org",
    "@type": "ImageObject",
    contentUrl: imageUrl,
    width: imageWidth,
    height: imageHeight,
    caption: imageAlt,
    url: imageUrl,
    description: imageAlt,
  }

  const person = {
    "@type": "Person",
    name: author,
  }

  const baseSchema = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: pageUrl,
      name: title,
      alternateName: defaultTitle,
      description: description,
      author: person,
      image: imageObject,
    },
  ]

  return isBlogPost
    ? [
        ...baseSchema,
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": pageUrl,
                name: title,
                image: imageObject,
              },
            },
          ],
        },
        {
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          url: pageUrl,
          name: title,
          alternateName: defaultTitle,
          headline: title,
          image: imageObject,
          description: description,
          author: person,
          mainEntityOfPage: {
            "@type": "WebSite",
            "@id": siteUrl,
          },
          datePublished: datePublished,
        },
      ]
    : baseSchema
}
